const AppRoutes = {
    BUDGETING: '/budgeting',
    CLIENTS: '/clients',
    CLIENT_CARS: '/clients/:clientId/cars',
    EDIT_BUDGETS: '/clients/:clientId/edit-budgets',
    AUTOMATIC_PRICING: '/clients/:clientId/automatic-pricing',
    CLIENT_COOP_REPORT: '/clients/:clientId/coop-report',
    USED_TOOL_SETTINGS: '/clients/:clientId/used-tool-settings',
    PROMO_AD_BUILDER: '/clients/promo-ad-builder',
    SALESFORCE: '/system/salesforce',
    SALESFORCE_PRODUCTS: '/system/salesforce/products',
    SALESFORCE_PROMOS: '/system/salesforce/promos',
    BUDGET_SPEND_WATCHDOG: '/budgeting/watchdog',
    INVENTORY_TRANSLATIONS: '/feeds/inventory-translations',
    USED_TOOL_STATUS: '/feeds/used-tool-status',
    AUTOMATIC_PRICING_CLIENTS: '/feeds/automatic-pricing-clients',
    AUTOMATIC_PRICING_MODELS: '/feeds/automatic-pricing-models',
    FEED_HASHES: '/feeds/hashes',
    SAFEGUARD: '/safeguard',
    LOGOUT: '/logout',
}

export default AppRoutes
