import { useState, useEffect, useCallback, useMemo } from 'react'
import { useBudgetList, useUsers } from '../hooks/apiHooks'
import {
    BudgetingItemList,
    BudgetListAccessors,
} from '../components/Budgeting/BudgetingItemList'
import {
    LineItemsOverview,
    LineItemDetails,
} from '../components/Budgeting/BudgetingDetails'
import {
    PeopleGrouping,
    PersonMultiselectWithSearch,
} from '../components/elements/PersonSelect'
import FileDialog from '../components/elements/FileDialog'
import CustomDropzone from '../components/elements/Dropzone'
import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { StatelessButtonTextBox } from '../components/elements/TextBoxes'
import { ExtendedLineItem, Sources, User } from '../apis/pearApi'
import { latestMonth } from '../utility/BudgetCalculations'
import { useLoginInfo, LoginStatus } from '../hooks/LoginInfo'
import { SectionCloud } from '../components/Layout'
import { useUrlQueryParameter } from '../hooks/useUrlQueryParameter'
import ErrorBoundary from '../components/ErrorBoundary'
import { combinedStatus } from '../utility/apis'
import ButtonGroup, { Button } from '../components/elements/Buttons'
import { StatelessSelect } from '../components/elements/StatelessSelect'
import Notification from '../components/elements/Notification'
import CsvLink from '../components/CsvLink'
import { haveSameContents } from '../utility/Arrays'
import { parseISO } from 'date-fns'
import { Toggle } from '../components/elements/Toggle'
import MonthDropdownWidget from '../components/elements/MonthDropdownWidget'
import { useMonthQueryParameter } from '../hooks/useMonthQueryParameter'
import {
    csvHeaders,
    csvFormattedData,
} from '../components/Budgeting/CSVFormatting'

import PearApi from '../apis/pearApi'

const GlobalFilter = {
    ALL: () => true,
}

// For use with data "caches"
const nullLineItem = {
    id: '',
    clientName: 'Unknown',
    clientId: 0,
    dailySpends: [],
    budget: 0,
    adsOnWeekends: true,
    source: 'Google',
    label: 'None',
    repId: 1,
} as ExtendedLineItem
const nullRep = {} as User

export const SortTypes = BudgetListAccessors

type SortKey = keyof typeof SortTypes
export type SortValue = typeof SortTypes[SortKey]

export const Budgeting = () => {
    const [filterFunction, setFilterFunction] = useState(() => GlobalFilter.ALL)

    const [showNotification, setShowNotification] = useState(false)
    const [message, setMessage] = useState('This is a notification!')
    const [type, setType] = useState('success') // or 'error'

    const handleShowNotification = () => {
        setShowNotification(true)
    }
    const handleHideNotification = () => {
        setShowNotification(false)
    }

    const setNewFilter = useCallback(
        (filterFunction) => {
            // Because useState accepts an initializer function as a parameter, we have
            // to give it a function that returns a function to assign it a function.
            setFilterFunction(() => filterFunction)
        },
        [setFilterFunction],
    )

    const [yearMonth] = useMonthQueryParameter()

    const {
        data: budgets,
        status: budgetsStatus,
        refetch: refetchBudgetList,
        isFetching: refetchingBudgetList,
    } = useBudgetList(yearMonth)

    const { data: users, status: usersStatus } = useUsers()
    const reps = useMemo(
        () => (users ? users.filter(({ clientCount }) => clientCount > 0) : []),
        [users],
    )

    const [filteredBudgets, setFilteredBudgets] = useState<ExtendedLineItem[]>(
        [],
    )

    useEffect(() => {
        if (!!budgets && budgets.length > 0) {
            const filteredBudgets = budgets.filter(filterFunction)
            setFilteredBudgets(filteredBudgets)
        } else {
            setFilteredBudgets([])
        }
    }, [filterFunction, budgets])

    const [sort, bareSetSort] = useUrlQueryParameter('sort', SortTypes.OEM_TYPE)
    const setSort = (sort, desc = false) =>
        bareSetSort(desc ? `${sort}-desc` : sort)

    // Setup state for table to communicate selected row to details panel.
    const [selectedId, setSelectedId] = useState<string | number>('')

    // Setup state for selected item and rep "caches". This will never be cleared, only
    // updated to facilitate smooth transitions on the right side. (Without this,
    // data is removed from the pane while it's fading out causing a weird looking
    // layout shift at the start of that transition.)
    const [selectedItem, setSelectedItem] =
        useState<ExtendedLineItem>(nullLineItem)
    useEffect(() => {
        if (selectedId !== '' && selectedId !== 0) {
            const possibleSelectedItem = filteredBudgets.find(
                (budget) => budget.id === selectedId,
            )
            setSelectedItem(possibleSelectedItem || nullLineItem)
        }
    }, [selectedId, filteredBudgets])

    const [selectedItemRep, setSelectedItemRep] = useState(nullRep)
    useEffect(() => {
        const possibleSelectedItemRep = reps.find(
            (rep) => rep.id === selectedItem.repId,
        )
        setSelectedItemRep(possibleSelectedItemRep || nullRep)
    }, [selectedItem, reps])

    const [search, setSearch] = useUrlQueryParameter('search')
    const filterToClientButton =
        search !== '' &&
        selectedId !== '' &&
        search === selectedItem?.clientName ? (
            <Button onClick={() => setSearch('')}>Clear Filter</Button>
        ) : selectedId !== '' ? (
            <Button onClick={() => setSearch(selectedItem?.clientName)}>
                Filter to Client
            </Button>
        ) : null

    const userNamesById = useMemo(
        () =>
            users
                ? users.reduce((map, user) => {
                      map[user.id] = user.name
                      return map
                  }, {})
                : {},
        [users],
    )

    const csvDownloadButton = useMemo(() => {
        const csvData = () => csvFormattedData(filteredBudgets, userNamesById)
        const csvStatus = combinedStatus(budgetsStatus, usersStatus)
        const isReady = csvStatus === 'success'
        return isReady ? (
            <CsvLink
                className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-cyan-600 hover:text-white"
                headers={csvHeaders}
                data={csvData}
                filename={`Pear Line Items - ${yearMonth}.csv`}
            >
                <ArrowDownTrayIcon
                    className="mr-2 h-5 w-5"
                    aria-hidden="true"
                />
                <span>Download Line Items</span>
            </CsvLink>
        ) : null
    }, [filteredBudgets, userNamesById, usersStatus, budgetsStatus, yearMonth])

    const [isFileDialogOpen, setIsFileDialogOpen] = useState(false)
    const [isLineItemFileDialogOpen, setIsLineItemFileDialogOpen] =
        useState(false)

    const openFileDialog = () => setIsFileDialogOpen(true)
    const openLineItemFileDialog = () => setIsLineItemFileDialogOpen(true)
    const closeFileDialog = () => setIsFileDialogOpen(false)
    const closeLineItemFileDialog = () => setIsLineItemFileDialogOpen(false)

    const csvUploadButton = () => {
        return (
            <button
                className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-cyan-600 hover:text-white"
                onClick={openFileDialog}
            >
                <ArrowUpTrayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Upload Budget Assignments
            </button>
        )
    }

    const csvUploadBudgetsButton = () => {
        return (
            <button
                className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-cyan-600 hover:text-white"
                onClick={openLineItemFileDialog}
            >
                <ArrowUpTrayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Upload Line Items
            </button>
        )
    }

    const [csvFile, setCsvFile] = useState(null)

    const handleUploadRequest = (file) => {
        setCsvFile(file)
        return false
    }

    const handleUploadFile = async () => {
        if (csvFile) {
            let response = null
            // Hide the dialog
            setIsFileDialogOpen(false)

            response = await PearApi.uploadBudgetAssignments(csvFile)
            setCsvFile(null)

            // cleaning up
            response = response.response ? response.response : response

            if (response.data && response.status) {
                setMessage(response.data.message)
                if (response.status <= 201) {
                    setType('success')
                    refetchBudgetList().then(() => {})
                } else {
                    setType('error')
                }
            } else {
                setMessage(
                    'There was an error uploading the file, please try again',
                )
                setType('error')
            }

            // trigger notification
            handleShowNotification()
        }
    }
    const handleUploadFileBudgeting = async () => {
        if (csvFile) {
            let response = null
            // Hide the dialog
            setIsLineItemFileDialogOpen(false)

            response = await PearApi.uploadBudgets(csvFile)
            setCsvFile(null)

            // cleaning up
            response = response.response ? response.response : response

            if (response.data && response.status) {
                setMessage(response.data.message)
                if (response.status <= 201) {
                    setType('success')
                    refetchBudgetList().then(() => {})
                } else {
                    setType('error')
                }
            } else {
                setMessage(
                    'There was an error uploading the file, please try again',
                )
                setType('error')
            }

            // trigger notification
            handleShowNotification()
        }
    }

    const [showLineItemDetails, setShowLineItemDetails] = useState(false)
    useEffect(() => {
        setShowLineItemDetails(
            selectedId !== '' &&
                filteredBudgets.findIndex(
                    (budget) => budget.id === selectedId,
                ) !== -1,
        )
    }, [selectedId, filteredBudgets])

    return (
        <div className="grid h-full grid-cols-12 gap-8">
            {showNotification && (
                <Notification
                    message={message}
                    type={type}
                    show={handleShowNotification}
                    onDismiss={handleHideNotification}
                />
            )}
            <div className="col-span-7 col-start-1 h-full min-h-0">
                <FileDialog
                    isOpen={isFileDialogOpen}
                    closeModal={closeFileDialog}
                    title="Upload Budget Assignments"
                    msg="File should contain 'Budgeter' and 'Salesforce Account Id' columns"
                >
                    <CustomDropzone onFileSelect={handleUploadRequest} />
                    <div className="mt-4 flex flex-row justify-end">
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-500 px-6 py-2 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-cyan-500 focus-visible:ring-offset-2 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
                            onClick={handleUploadFile}
                            disabled={!csvFile}
                        >
                            Upload
                        </button>
                    </div>
                </FileDialog>
                <FileDialog
                    isOpen={isLineItemFileDialogOpen}
                    closeModal={closeLineItemFileDialog}
                    title="Upload Line Items"
                    msg=" File should contain: "
                    requiredParams={[
                        'Salesforce Account ID',
                        'Asset ID',
                        'Source',
                        'Label',
                        'Budget',
                    ]}
                >
                    <CustomDropzone onFileSelect={handleUploadRequest} />
                    <div className="mt-4 flex flex-row justify-end">
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-cyan-500 px-6 py-2 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-cyan-500 focus-visible:ring-offset-2 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
                            onClick={handleUploadFileBudgeting}
                            disabled={!csvFile}
                        >
                            Upload
                        </button>
                    </div>
                </FileDialog>
                <SectionCloud className="flex h-full flex-col">
                    <h1 className="sr-only">Budgeting</h1>
                    <div className="flex-none">
                        <BudgetingItemFilter
                            reps={reps}
                            setFilter={setNewFilter}
                            sort={sort}
                            setSort={setSort}
                            filterToClientButton={filterToClientButton}
                        />
                    </div>
                    <div className="flex-1">
                        <BudgetingItemList
                            data={filteredBudgets}
                            status={budgetsStatus}
                            isFetching={refetchingBudgetList}
                            sort={sort}
                            setSort={setSort}
                            setSelectedId={setSelectedId}
                            yearMonth={yearMonth}
                        />
                    </div>
                </SectionCloud>
            </div>
            {budgetsStatus !== 'loading' && !refetchingBudgetList && (
                <div className="col-span-5 h-full">
                    <ErrorBoundary>
                        <div className="relative h-full">
                            <LineItemDetails
                                show={showLineItemDetails}
                                item={selectedItem}
                                rep={selectedItemRep}
                                yearMonth={yearMonth}
                            />

                            <LineItemsOverview
                                show={!showLineItemDetails}
                                data={filteredBudgets}
                                csvDownloadButton={csvDownloadButton}
                                csvUploadButton={csvUploadButton}
                                csvUploadBudgetsButton={csvUploadBudgetsButton}
                                yearMonth={yearMonth}
                            />
                        </div>
                    </ErrorBoundary>
                </div>
            )}
        </div>
    )
}

const SpecificFilter = {
    REP: (ids: number[]) => {
        return (lineItem: ExtendedLineItem) => {
            return ids.includes(lineItem.repId)
        }
    },
    SOURCE: (source) => (lineItem: ExtendedLineItem) => {
        return source === ANY_SOURCE || lineItem.source === source
    },
    SEARCH: (searchText, searchType) => (lineItem: ExtendedLineItem) => {
        // Skip and include everything if there's no search text.
        if (searchText === '') {
            return true
        }

        const search = searchText.toLowerCase()
        const code = lineItem.code?.toLowerCase() ?? ''
        const sortTypeLabel = lineItem.sortTypeLabel.toLowerCase()
        const clientName = lineItem.clientName.toLowerCase()
        const label = lineItem.label.toLowerCase()
        const matchAll = ['ea', 'ca'].includes(searchType)
        const exclude = ['e', 'ea'].includes(searchType)

        const includesSearch = search
            .split(';')
            .reduce((includesThisTerm, term) => {
                // Don't use an empty term for inclusion or exclusion
                if (term === '') {
                    return includesThisTerm
                }

                const included =
                    code.includes(term) ||
                    sortTypeLabel.includes(term) ||
                    clientName.includes(term) ||
                    label.includes(term)

                if (matchAll) {
                    return includesThisTerm && included
                }

                return includesThisTerm || included
            }, matchAll)

        return exclude !== includesSearch
    },
    CURRENT_ITEMS_ONLY: (enabled) => (lineItem: ExtendedLineItem) => {
        if (!enabled) {
            return true
        }
        const { endDate } = lineItem
        return (
            endDate === undefined ||
            endDate === null ||
            endDate === '' ||
            parseISO(endDate) > new Date()
        )
    },
}

const ANY_SOURCE = 'Any'
const sourceOptions = [ANY_SOURCE]
    .concat(Object.values(Sources))
    .map((source) => ({
        id: source,
        label: source,
    }))

// Could be any of the 4 unreserved characters (-_.~) or a letter to avoid percent-encoding.
const REP_ID_SEPARATOR = '.'

const BudgetingItemFilter = ({
    reps,
    setFilter,
    sort,
    setSort,
    filterToClientButton,
}) => {
    const [source, setUrlSource] = useUrlQueryParameter('source', ANY_SOURCE)
    const setSource = ({ id: source }) => setUrlSource(source)
    const firstMonth = '2022-01'

    const [urlRepIds, setUrlRepIds] = useUrlQueryParameter(
        'repId',
        PeopleGrouping.NO_SELECTION.id.toString(10),
    )
    const setRepIds = (repIds: number[]) => {
        const newString = repIds.join(REP_ID_SEPARATOR)
        const newValue = haveSameContents(
            repIds,
            reps.map((rep) => rep.id),
        )
            ? PeopleGrouping.ALL.id.toString(10)
            : newString !== ''
            ? newString
            : PeopleGrouping.NONE.id.toString(10)
        setUrlRepIds(newValue)
    }

    const { user, status } = useLoginInfo()
    const currentUserId =
        status === LoginStatus.loggedIn ? user.id : PeopleGrouping.ALL.id
    const currentUserTeamIds = user.team

    const [initialRepIdsString] = useState(urlRepIds)
    const [initiallySelectedRepIds, setInitiallySelectedRepIds] = useState(
        reps.map((rep) => rep.id),
    )
    // Backwards compatibility; taking care of the default, no rep id case; and
    // turning the URL string into an array
    useEffect(() => {
        // Turn no selection code into current user (if a rep) or all reps if not.
        if (
            initialRepIdsString === PeopleGrouping.NO_SELECTION.id.toString(10)
        ) {
            if (reps.some(({ id }) => id === currentUserId)) {
                setRepIds([currentUserId])
                setInitiallySelectedRepIds([currentUserId])
            } else {
                setRepIds(reps.map((rep) => rep.id))
                setInitiallySelectedRepIds(reps.map((rep) => rep.id))
            }
        }
        // Turn none code into empty repId (?repId=  )
        else if (initialRepIdsString === PeopleGrouping.NONE.id.toString(10)) {
            setRepIds([])
            setInitiallySelectedRepIds([])
        }
        // Turn all code into full array
        else if (initialRepIdsString === PeopleGrouping.ALL.id.toString(10)) {
            const newRepIds = reps.map(({ id }) => id)
            setRepIds(newRepIds)
            setInitiallySelectedRepIds(newRepIds)
        } else if (initialRepIdsString !== PeopleGrouping.ALL.id.toString(10)) {
            if (
                reps.some(({ id }) =>
                    initialRepIdsString.includes(id.toString(10)),
                )
            ) {
                const initialRepIds = initialRepIdsString
                    .split(REP_ID_SEPARATOR)
                    .map((str) => parseInt(str, 10))
                setRepIds(initialRepIds)
                setInitiallySelectedRepIds(initialRepIds)
            } else {
                setRepIds(reps.map((rep) => rep.id))
                // Use InitiallySelectedRepIds' default of all reps
            }
        }
        // Since the setRepId function changes every render, don't include it in the list.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialRepIdsString, reps])

    const [search, setSearch] = useUrlQueryParameter('search')
    const [searchType, setSearchType] = useUrlQueryParameter('searchType')

    const searchButtonOptionsMap = {
        '': 'Contains',
        e: 'Excludes',
        ca: 'Contains All',
        ea: 'Excludes All',
    }
    const searchButtonOption = searchButtonOptionsMap[searchType]
    const nextSearchButtonOption = () => {
        if (searchType === 'ea') {
            setSearchType('')
        } else if (searchType === '') {
            setSearchType('e')
        } else if (searchType === 'e') {
            setSearchType('ca')
        } else if (searchType === 'ca') {
            setSearchType('ea')
        } else {
            setSearchType('')
        }
    }

    const [currentItemsOnly, setUrlCurrentItemsOnly] = useUrlQueryParameter(
        'currentOnly',
        'false',
    )
    const setCurrentItemsOnly = (enabled) =>
        setUrlCurrentItemsOnly(enabled ? 'true' : 'false')

    useEffect(
        () => {
            setFilter(
                (lineItem: ExtendedLineItem) =>
                    SpecificFilter.CURRENT_ITEMS_ONLY(
                        currentItemsOnly === 'true',
                    )(lineItem) &&
                    SpecificFilter.REP(
                        urlRepIds === PeopleGrouping.ALL.id.toString(10)
                            ? reps.map((rep) => rep.id)
                            : urlRepIds
                                  .split(REP_ID_SEPARATOR)
                                  .map((str) => parseInt(str, 10)),
                    )(lineItem) &&
                    SpecificFilter.SOURCE(source)(lineItem) &&
                    SpecificFilter.SEARCH(search, searchType)(lineItem),
            )
        },
        // We're not including the setFilter fuction in the deps because we only
        // want to run this when the filter choices change.
        // (The new function will still be used if/when it changes.)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [urlRepIds, reps, source, search, searchType, currentItemsOnly],
    )

    const currentMonth = latestMonth()
    const [yearMonth, setYearMonth] = useMonthQueryParameter()

    const sortOptions = [
        { value: 'oem-type', name: 'OEM/Type' },
        { value: 'time-zone', name: 'Time Zone' },
        { value: 'none', name: 'None' },
    ] as const
    const sortOption =
        sort === SortTypes.OEM_TYPE
            ? 'oem-type'
            : sort === SortTypes.TIME_ZONE
            ? 'time-zone'
            : 'none'
    const setSortOption = (value: typeof sortOptions[number]['value']) => {
        if (value === 'oem-type') {
            setSort(SortTypes.OEM_TYPE)
        } else if (value === 'time-zone') {
            setSort(SortTypes.TIME_ZONE)
        } else {
            setSort(SortTypes.PACING_PERCENT, true)
        }
    }

    return (
        <div className="px-4 pt-4 pb-5">
            <div className="grid grid-cols-[15.7rem_1fr] grid-rows-2 gap-y-4 gap-x-6">
                <div className="pr-1">
                    <PersonMultiselectWithSearch
                        onChange={(people) =>
                            setRepIds(people.map((person) => person.id))
                        }
                        people={reps}
                        initiallySelectedIds={initiallySelectedRepIds}
                        myTeamIds={currentUserTeamIds}
                    />
                </div>
                <div className="flex gap-6">
                    <div className="w-36">
                        <StatelessSelect
                            className="mt-1"
                            label="Source"
                            options={sourceOptions}
                            value={sourceOptions.find(
                                ({ id }) => id === source,
                            )}
                            onChange={setSource}
                        />
                    </div>
                    <div className="flex-auto pt-8">
                        <Toggle
                            label="Ended Items"
                            enabled={currentItemsOnly !== 'true'}
                            setEnabled={(endedItems) =>
                                setCurrentItemsOnly(!endedItems)
                            }
                        />
                    </div>
                    <div className="flex flex-initial gap-4">
                        <MonthDropdownWidget
                            month={yearMonth}
                            setMonth={setYearMonth}
                            firstMonth={firstMonth}
                            lastMonth={currentMonth}
                        />
                    </div>
                </div>
                <div className="pr-1">
                    <ButtonGroup
                        className="w-full"
                        label="Sort by"
                        choices={sortOptions}
                        value={sortOption}
                        setValue={setSortOption}
                    />
                </div>
                <div className="flex gap-6">
                    <div className="flex flex-1 gap-2">
                        <StatelessButtonTextBox
                            className="flex-auto"
                            label="Search"
                            text={search}
                            onChange={setSearch}
                            spellCheck="false"
                            buttonText={searchButtonOption}
                            buttonOnClick={nextSearchButtonOption}
                        />
                    </div>
                    {filterToClientButton !== null && (
                        <div className="flex flex-initial justify-start pt-6">
                            {filterToClientButton}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
