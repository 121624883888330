import axios from 'axios'
import AppRoutes from '../pages/routes'
import {
    budgetingOverviewDetails,
    isProjectedWithinFivePercent,
} from '../utility/BudgetCalculations'
import { typeSortFormat } from '../utility/Formatting'

const BASE_DOMAIN = process.env.REACT_APP_PEAR_API_BASE_URL
const BASE_URL = BASE_DOMAIN + '/api'
axios.defaults.baseURL = BASE_URL

// As instructed for Laravel Sanctum authentication from a SPA.
// https://laravel.com/docs/8.x/sanctum#cors-and-cookies
axios.defaults.withCredentials = true

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (
            error.response.status === 401 &&
            window.location.pathname !== AppRoutes.LOGOUT
        ) {
            window.location.href = AppRoutes.LOGOUT
        }
        return error
    },
)

/**
 * Below are type definitions, verification functions, and the actual API call
 * functions. Many call functions use the verifications functions to show errors
 * on dev when the received types don't match what's expected. They should always
 * follow one of these two formats:
 *    reviewChecklist(verify______, 'Description', data)
 *    reviewDataList(verify______, 'Description of Each Item in List', dataList)
 *
 * Running a verify______ type function by itself is depercated as it won't be
 * removed on production.
 */

export const Sources = {
    GOOGLE: 'Google',
    FACEBOOK: 'Facebook',
    MICROSOFT: 'Microsoft',
    STACK_ADAPT: 'StackAdapt',
    LINKEDIN: 'LinkedIn',
    TIKTOK: 'TikTok',
} as const

export const NO_LINKED_ASSET = 'not-linked-to-salesforce-asset'

const loginWithGoogleAuth = async (code) => {
    // Using full URL's as these don't have the /api part.
    await axios.get(BASE_DOMAIN + '/sanctum/csrf-cookie')
    const response = await axios.post(BASE_DOMAIN + '/auth/sso', {
        code,
    })
    return response.data
}

const logout = async () => {
    const response = await axios.post(BASE_DOMAIN + '/auth/logout')
    return response.data
}

const refreshToken = async (email) => {
    const response = await axios.post(BASE_DOMAIN + '/auth/refresh-token', {
        email: email,
    })
    return response.data
}

const isNumber = (value) => typeof value === 'number'

const isNonNegativeNumber = (value) => isNumber(value) && value >= 0

const isInteger = (value) => isNumber(value) && value % 1 === 0

const isPositiveInteger = (value) => isInteger(value) && value >= 0

/** Checks whether a value is or isn't an integer greater than or equal to zero. */
const isWholeNumber = (value) => isNonNegativeNumber(value) && value % 1 === 0

const isNonZeroNumber = (value) =>
    isNumber(value) && value !== 0 && value !== -0

const isString = (value) => typeof value === 'string'

/* const isWholeNumberOrString = (value) =>
    isWholeNumber(value) || typeof value === 'string' */

const isFilledString = (value) => isString(value) && value.length > 0

/** Checks for a valid ISO 8601 timestamp with at least the full date. */
const isIsoTimestamp = (value) => isString(value) && value.length >= 10

/** Checks for a valid ISO 8601 timestamp with the full date. */
const isIsoDate = (value) => isIsoTimestamp(value)

const isBoolean = (value) => !!value === value

const isTrue = (value) => true === value

const isArray = (check: (value: any) => boolean, list) =>
    Array.isArray(list) &&
    list.reduce((isValid, value) => isValid && check(value), true)

const isAdSource = (value) =>
    isString(value) && Object.values(Sources).includes(value)

export type ClientType =
    | 'Auto'
    | 'Capital Equipment'
    | 'Custom'
    | 'New Industries'
    | 'Jacuzzi'

const isType = (value) =>
    isString(value) &&
    [
        'Auto',
        'Capital Equipment',
        'Custom',
        'New Industries',
        'Jacuzzi',
    ].includes(value)

// Checks for 'real' objects – not functions or arrays
const isObject = (value) =>
    typeof value === 'object' && value !== null && !Array.isArray(value)

const isConfig = (object: object) =>
    isObject(object) &&
    Object.keys(object).reduce(
        (allGood, key) => allGood && isFilledString(key),
        true,
    ) &&
    Object.values(object).reduce(
        (allGood, value) =>
            allGood && (isBoolean(value) || isFilledString(value)),
        true,
    )

const isOptional = (check: (value: any) => boolean, value: any) =>
    typeof value === 'undefined' || check(value)

const isNullable = (check: (value: any) => boolean, value: any) =>
    value === null || check(value)

const isArrayOf = (check: (value: any) => boolean, value: any) =>
    Array.isArray(value) &&
    value.reduce((allGood, item) => allGood && check(item), true)

interface Checklist {
    [key: string]: boolean
}

const isProduction = process.env.NODE_ENV === 'production'

/** Call the checklist function, log the first error listed (including the
 *  data and label provided), and then return whether everything was error free
 *  or not. Also, use a noop function if not in a dev environment so all checking
 *  and checking code can be compiled out of the bundle.
 */
const reviewChecklist = isProduction
    ? () => true
    : (
          checkFn: (data: any) => Checklist,
          dataLabel: string,
          dataToCheck: any,
          checkForUnexpectedProperties: boolean = true,
      ) => {
          const checklist = checkFn(dataToCheck)
          const expectedProperties = Object.keys(checklist)
          // Check for properties we expect
          Object.values(checklist).forEach((valid, index) => {
              if (!valid) {
                  const problem = expectedProperties[index]
                  console.error(`Wrong type for ${problem} in ${dataLabel}:`)
                  console.error(dataToCheck)
                  return false
              }
          })

          // Check for extra properties we didn't expect
          if (checkForUnexpectedProperties) {
              Object.getOwnPropertyNames(dataToCheck).forEach(
                  (existingProperty) => {
                      if (!expectedProperties.includes(existingProperty)) {
                          console.error(
                              `Found unexpected property ${existingProperty} in ${dataLabel}:`,
                          )
                          console.error(dataToCheck)
                          return false
                      }
                  },
              )
          }
          return true
      }

/**
 * Takes a list of data objects and passes each one in turn to a checklist function.
 * Is also a noop function if not on a dev environment to skip all checking.
 *
 * @param checklistFn Function to check the data and return a checklist.
 * @param itemLabel Name of each item in the data list (e.g. 'Food' in foods.)
 * @param list List of data objects to be passed to the checklistFn.
 */
const reviewDataList = isProduction
    ? () => true
    : (
          checklistFn: (value: any) => Checklist,
          itemLabel: string,
          list: any[],
          checkForUnexpectedProperties: boolean = true,
      ) => {
          if (!Array.isArray(list)) {
              console.error(
                  'A non-array was passed to the list parameter of reviewDataList():',
                  list,
              )
              return false
          }
          return list
              .map((item) =>
                  reviewChecklist(
                      checklistFn,
                      itemLabel,
                      item,
                      checkForUnexpectedProperties,
                  ),
              )
              .reduce(
                  (allGood, thisOneIsGood) => allGood && !!thisOneIsGood,
                  true,
              )
      }

/** A string in ISO 8601 format intended to represent a date. e.g. "2022-09-01".
 *  TODO: Improve this type if/when possible.
 */
export type IsoDate = string

/** A string in ISO 8601 format intended to represent a specific moment.
 *  e.g. "2022-09-01T24" or "2022-09-01T23:59:00".
 *  TODO: Improve this type if/when possible.
 */
export type IsoTimestamp = string
export interface Spend {
    /** ISO 8601 timestamp - preferred format is "2021-04-20" (no time) or
     * "2021-04-20T24" (with time as midnight at the end of the day represented -
     * with or without trailing :00's)
     */
    date: IsoTimestamp
    /** Amount spent on this day in dollars. */
    spend: number
}

const verifySpend = (thisSpend) => {
    const { date, spend } = thisSpend
    let checks: Checklist = {}
    checks.date = isIsoTimestamp(date)
    checks.spend = isNonNegativeNumber(spend)

    return checks
}

export type Source = typeof Sources[keyof typeof Sources]

export interface LineItem {
    /** Unique ID for this line item*/
    id: string
    /** Name of client this line item is for */
    clientId: number
    /** Name of client this line item is for */
    clientName: string
    /** Dealer code if present */
    code?: string
    /** IANA time zone code */
    timeZone: string
    /** Brand/Make for the dealer (not always present) */
    oem?: string
    /** Adpearance category for the client */
    type: ClientType
    /** Advertising Platform, Source of Ad Spend */
    source: Source

    clientSourceId?: number
    /** Id of the Account in the Source Ad Platform */
    adAccountId: string
    /** Id of the Account in Salesforce */
    salesforceId?: string
    /** Name of the Salesforce product */
    productName?: string
    /** Name of the Salesforce promo */
    promoName?: string
    /** A user assigned label within the app */
    label: string
    /** ISO string of first day for this line item like: "2022-09-03",
     *  If absent, budget starts on the first day of the month. Is expected
     *  to be within the month requested.
     */
    startDate?: IsoDate
    /** ISO string of last day for this line item like: "2022-09-23"
     *  If absent, budget ends on the last day of the month. Is expected to be
     *  within the month requested.
     */
    endDate?: IsoDate
    /** ID for the user assigned to manage this client */
    repId?: number
    /** ID for the strategist assigned to this client */
    strategistId?: number
    /** ID for the regional sales manager (RSM) assigned to this client */
    salesManagerId?: number
    /** Amount budgeted for the month in dollars */
    budget: number
    /** Actual spends from the current month */
    dailySpends: Spend[]
    /** Whether the client shows ads on weekends. */
    adsOnWeekends: boolean
    /** How many pending budget changes the client has. */
    clientPendingBudgetChanges: number
}

const verifyLineItem = (lineItem) => {
    const {
        id,
        clientId,
        clientName,
        code,
        timeZone,
        oem,
        type,
        source,
        clientSourceId,
        adAccountId,
        salesforceId,
        productName,
        promoName,
        label,
        startDate,
        endDate,
        repId,
        strategistId,
        salesManagerId,
        budget,
        dailySpends,
        adsOnWeekends,
        clientPendingBudgetChanges,
    } = lineItem
    let checks: Checklist = {}
    checks.dailySpends = reviewDataList(verifySpend, 'Daily Spend', dailySpends)
    checks.id = isFilledString(id)
    checks.clientId = isWholeNumber(clientId)
    checks.clientName = isString(clientName)
    checks.code = isOptional(isFilledString, code)
    checks.timeZone = isString(timeZone)
    checks.oem =
        (typeof oem === 'undefined' && type !== 'Auto') || isString(oem)
    checks.type = isType(type)
    checks.source = isAdSource(source)
    checks.adAccountId = isFilledString(adAccountId)
    checks.salesforceId = isOptional(isFilledString, salesforceId)
    checks.productName = isOptional(isFilledString, productName)
    checks.promoName = isOptional(isFilledString, promoName)
    checks.label = isString(label)
    checks.startDate = isOptional(isIsoDate, startDate)
    checks.endDate = isOptional(isIsoDate, endDate)
    checks.repId = isOptional(isWholeNumber, repId)
    checks.clientSourceId = isOptional(isWholeNumber, clientSourceId)
    checks.strategistId = isOptional(isPositiveInteger, strategistId)
    checks.salesManagerId = isOptional(isPositiveInteger, salesManagerId)
    checks.budget = isNonNegativeNumber(budget)
    checks.adsOnWeekends = isBoolean(adsOnWeekends)
    checks.clientPendingBudgetChanges = isNonNegativeNumber(
        clientPendingBudgetChanges,
    )

    return checks
}

export type ExtendedLineItem = LineItem & {
    latestPercent: number
    total: number
    dayBeforePercent: number
    sortTypeLabel: string
    sortType: string
    isProjectedWithinFivePercent: boolean
}

const budgetList = async (month: string): Promise<ExtendedLineItem[]> => {
    const response = await axios.get(
        month !== '' ? '/line-items?month=' + month : '/line-items',
    )
    const lineItems = response.data
    reviewDataList(verifyLineItem, 'Line Item', lineItems)
    // eslint-disable-next-line no-console
    console.timeEnd('calcs')
    // eslint-disable-next-line no-console
    console.time('calcs')
    const completeLineItems = lineItems.map((lineItem) => {
        const sortTypeLabel = typeSortFormat(
            lineItem.type,
            lineItem.oem ?? false,
        )
        const endDate = lineItem.endDate ?? null
        const sortType = `${sortTypeLabel} | ${lineItem.clientName}`
        const { latestPercent, total, dayBeforePercent } =
            budgetingOverviewDetails(lineItem, month)
        const isOnTrack = isProjectedWithinFivePercent(lineItem, month)
        return {
            ...lineItem,
            latestPercent,
            total,
            dayBeforePercent,
            sortTypeLabel,
            sortType,
            isProjectedWithinFivePercent: isOnTrack,
            endDate,
        }
    })
    // eslint-disable-next-line no-console
    console.timeEnd('calcs')
    return completeLineItems
}

export interface Message {
    message: string
}

const importSpends = async (
    clientId: string | number,
    source: Source,
): Promise<Message> => {
    const response = await axios.post(`/spend-import/${clientId}`, { source })
    return response.data
}

export interface User {
    /** Unique ID for this rep */
    id: number
    email: string
    name: string
    /** URL for Google avatar image for this rep */
    avatar: string | null
    admin: boolean
    /** Number of clients this user is assigned to manage */
    clientCount: number
    /** Arrary of ids of team members this person manages. */
    team?: number[]
}

const verifyUser = (user) => {
    const { id, name, avatar, admin, email, clientCount, team } = user
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isString(name)
    checks.email = isString(email)
    checks.avatar = isNullable(isString, avatar)
    checks.admin = isBoolean(admin)
    checks.clientCount = isOptional(isWholeNumber, clientCount)
    checks.team = isOptional(
        (teamIds) => isArrayOf(isPositiveInteger, teamIds),
        team,
    )

    return checks
}

const users = async (): Promise<User[]> => {
    const response = await axios.get('/users')
    const users = response.data
    reviewDataList(verifyUser, 'User', users)
    return users
}

const currentUser = async (): Promise<User> => {
    const response = await axios.get('/me')
    const currentUser = response.data
    reviewDataList(verifyUser, 'Current User', currentUser)
    return currentUser
}

export type SpendType =
    | 'Branded'
    | 'Brand/Name'
    | 'Brand/OEM'
    | 'Dealership'
    | 'Display'
    | 'Local/OEM'
    | 'Models'
    | 'New'
    | 'Other'
    | 'Parts'
    | 'Rent'
    | 'Used'
    | 'Video'

export interface CampaignSpend {
    name: string
    spend: number
}

export interface SpendPortion {
    /** The category of the spend */
    type: SpendType
    /** List of campaigns for this type */
    campaigns: CampaignSpend[]
    /** In dollars */
    spend: number
    /** Search Impression Score - percentage of times ads were shown when possible.
     * sis, sisBudget, and sisRank should total to 100 (%)
     */
    sis?: number
    /** Percentage of times ads didn't show due to budget */
    sisLostFromBudget?: number
    /** Percentage of times ads didn't show due to rank */
    sisLostFromRank?: number
}

const verifyCampaign = (campaign) => {
    const { name, spend } = campaign
    let checks: Checklist = {}
    checks.name = isString(name)
    checks.spend = isNonNegativeNumber(spend)

    return checks
}

const verifySpendPortion = (spendPortion: SpendPortion) => {
    const { type, campaigns, spend, sis, sisLostFromBudget, sisLostFromRank } =
        spendPortion
    let checks: Checklist = {}
    checks.type =
        typeof type === 'string' &&
        [
            'Branded',
            'Brand/Name',
            'Brand/OEM',
            'Brand-Specific',
            'Categories',
            'Conquest',
            'Dealership',
            'Dealer/Name',
            'Display',
            'General',
            'Geo-Prospecting',
            'Local/OEM',
            'Models',
            'New',
            'Other',
            'Oracle',
            'Parts',
            'Remarketing',
            'Rent',
            'Service',
            'Used',
            'Video',
        ].includes(type)
    checks.campaigns = reviewDataList(verifyCampaign, 'Campaign', campaigns)
    checks.spend = isNonNegativeNumber(spend)
    checks.sis = isOptional(isNonNegativeNumber, sis)
    checks.sisLostFromBudget = isOptional(
        isNonNegativeNumber,
        sisLostFromBudget,
    )
    checks.sisLostFromRank = isOptional(isNonNegativeNumber, sisLostFromRank)

    return checks
}

const spendBreakdown = async (
    lineItemId: string,
    month?: string,
): Promise<SpendPortion[]> => {
    if (lineItemId === '') {
        return []
    }
    const response = await axios.get(
        month
            ? `spend-breakdown/${lineItemId}?month=${month}`
            : `spend-breakdown/${lineItemId}`,
    )
    const spendBreakdown = response.data
    reviewDataList(verifySpendPortion, 'Spend Portion', spendBreakdown)
    return spendBreakdown
}

export interface BudgetLineItem {
    /** User defined label for this line item or 'Else' as the default */
    label: string
    /** Amount in dollars */
    budget: number
    /** Rollover and adjustment in one. In dollars. */
    adjustment?: number
    /** Flag to say this was suggested by Salesforce info, not in Pear yet */
    suggested?: true
    /** Should the ads run on weekends? */
    adsOnWeekends?: boolean
    /** ISO string of first day for this line item like: "2022-09-03" */
    startDate?: IsoDate
    /** ISO string of last day for this line item like: "2022-09-03" */
    endDate?: IsoDate
}

const verifyBudgetLineItem = (lineItem: BudgetLineItem) => {
    const {
        label,
        budget,
        adjustment,
        suggested,
        adsOnWeekends,
        startDate,
        endDate,
    } = lineItem
    let checks: Checklist = {}
    checks.label = isFilledString(label)
    checks.budget = isNonNegativeNumber(budget)
    checks.adjustment = isOptional(isNonZeroNumber, adjustment)
    checks.suggested = isOptional(isTrue, suggested)
    checks.adsOnWeekends = isOptional(isBoolean, adsOnWeekends)
    checks.startDate = isOptional(isIsoDate, startDate)
    checks.endDate = isOptional(isIsoDate, endDate)

    return checks
}

export interface Asset {
    /** Unique ID from Salesforce or placeholder if not linked to Salesforce asset */
    id: string
    /** Name in Salesforce or explanation if not linked to Salesforce asset */
    name: string
    /** Ad Spend (budget) in Salesforce or 0 if not linked to Salesforce asset */
    budget: number
    /** Package Price in Salesforce or 0 if not linked to Salesforce asset */
    package: number
    /** Note from Salesforce if present */
    note?: string
    /** Campaign type from Salesforce if present */
    campaignType?: string
    /** Dig Ad Type from Salesforce if present */
    digAdType?: string
    /** Promo name from Salesforce if it's a promo */
    promo?: string
    /** Line Items from Pear or suggested based on budget if none in Pear */
    lineItems: BudgetLineItem[]
}

const verifyAsset = (asset: Asset) => {
    const {
        id,
        name,
        budget,
        package: packagePrice, // future reserved word, can't be used for variables
        note,
        campaignType,
        digAdType,
        promo,
        lineItems,
    } = asset
    let checks: Checklist = {}
    checks.id = isFilledString(id)
    checks.name = isFilledString(name)
    checks.budget = isNonNegativeNumber(budget)
    checks.package = isNonNegativeNumber(packagePrice)
    checks.note = isOptional(isFilledString, note)
    checks.campaignType = isOptional(isFilledString, campaignType)
    checks.digAdType = isOptional(isFilledString, digAdType)
    checks.promo = isOptional(isFilledString, promo)
    checks.lineItems = reviewDataList(
        verifyBudgetLineItem,
        'BudgetLineItem',
        lineItems,
    )

    return checks
}
export interface Budget {
    /** Ad info source */
    source: Source
    /** Ad account ID */
    accountId: string
    /** List of budget assets */
    assets: Asset[]
}

const verifyBudget = (budget: Budget) => {
    const { source, accountId, assets } = budget
    let checks: Checklist = {}
    checks.source = isString(source)
    checks.accountId = isString(accountId)
    checks.assets = reviewDataList(verifyAsset, 'Asset', assets)

    return checks
}

export interface BudgetList {
    /** Unique ID for this budget, not present in Salesforce budgets */
    id: number
    /** Client name */
    name: string
    opportunityUrl: string
    budgets: Budget[]
    error: string
}

const verifyBudgetList = (budget: BudgetList) => {
    const { id, name, opportunityUrl, budgets } = budget
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isFilledString(name)
    checks.opportunityUrl = isFilledString(opportunityUrl)
    checks.budgets = reviewDataList(verifyBudget, 'Budget', budgets)

    return checks
}

const budget = async (clientId): Promise<BudgetList> => {
    const response = await axios.get(`budgets/${clientId}`, {
        validateStatus: function (status) {
            return true
        },
    })
    const budgetList = response.data
    if (response.status === 200) {
        reviewChecklist(verifyBudgetList, 'Budget List Item', budgetList)
    }
    return budgetList
}

const saveBudget = async (clientId, budgetInfo) => {
    const result = await axios.post(`budgets/${clientId}`, budgetInfo)
    return result
}

export interface SourceWithAccountId {
    source: Source
    accountId?: string | null
}

const verifySourceWithAccountId = (
    sourceWithAccountId: SourceWithAccountId,
) => {
    const { source, accountId } = sourceWithAccountId
    let checks: Checklist = {}
    checks.source = isAdSource(source)
    checks.accountId = isOptional(
        (value) => isNullable(isFilledString, value),
        accountId,
    )

    return checks
}

export interface Client {
    id: number
    name: string
    dealerCode?: string
    timeZone: string
    website?: string
    shortName?: string
    fourbotId?: number
    foureyesId?: number
    repId?: number
    salesforceId?: string
    type: ClientType
    config: {
        facebookPageId: string
        automaticPricingEnabled: boolean
        usedToolEnabled: boolean
        usedToolCertifiedOnly: boolean
        usedToolOnlyMyLocation: boolean
        tikTokLeadsEnabled: boolean
        partsServiceOnly: boolean
        promoAdBuilderEnabled: boolean
        fourbotPrivateSource: boolean
        accountLevelVdpConversions: boolean
    }
    sources: SourceWithAccountId[]
    pendingBudgetChanges: number
}

const verifyClient = (client: Client) => {
    const {
        id,
        name,
        dealerCode,
        timeZone,
        website,
        shortName,
        fourbotId,
        foureyesId,
        repId,
        salesforceId,
        type,
        config,
        sources,
        pendingBudgetChanges,
    } = client
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isFilledString(name)
    checks.dealerCode = isOptional(isFilledString, dealerCode)
    checks.timeZone = isFilledString(timeZone)
    checks.website = isOptional(isFilledString, website)
    checks.shortName = isOptional(isString, shortName)
    checks.fourbotId = isOptional(isPositiveInteger, fourbotId)
    checks.foureyesId = isOptional(isPositiveInteger, foureyesId)
    checks.repId = isOptional(isPositiveInteger, repId)
    checks.salesforceId = isOptional(isFilledString, salesforceId)
    checks.type = isType(type)
    checks.config = isConfig(config)
    checks.sources = reviewDataList(
        verifySourceWithAccountId,
        'Source with Account ID',
        sources,
    )
    checks.pendingBudgetChanges = isPositiveInteger(pendingBudgetChanges)

    return checks
}

const clients = async (): Promise<Client[]> => {
    const response = await axios.get('clients')
    const clientList = response.data
    reviewDataList(verifyClient, 'Client', clientList)
    return clientList
}

const client = async (clientId): Promise<Client> => {
    if (!(clientId > 0)) {
        return {} as Client
    }
    const response = await axios.get(`clients/${clientId}`)
    const client = response.data
    reviewChecklist(verifyClient, 'Client', client)
    return client
}

const saveClient = async (clientId, clientInfo) => {
    const result = await axios.post(`clients/${clientId}`, clientInfo)
    return result
}

export interface ClientCampaign {
    id: number
    name: string
    source: Source
    status: string
    /** Month-to-date spend for this campaign. */
    spend: number
}

const verifyClientCampaign = (campaign: ClientCampaign) => {
    const { id, name, source, status, spend } = campaign
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isFilledString(name)
    checks.source = isAdSource(source)
    checks.status = isFilledString(status)
    checks.spend = isNonNegativeNumber(spend)

    return checks
}

const clientCampaigns = async (
    clientId: string | number,
): Promise<ClientCampaign[]> => {
    const response = await axios.get(`campaigns/${clientId}`)
    const campaignList = response.data
    reviewDataList(verifyClientCampaign, 'Client Campaign', campaignList)
    return campaignList
}

export interface PromoAd {
    id: number
    name: string
    image: string
}

const verifyPromoAd = (ad: PromoAd) => {
    const { id, name, image } = ad
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isFilledString(name)
    checks.image = isFilledString(image)

    return checks
}

const promoAds = async (): Promise<PromoAd[]> => {
    const response = await axios.get('promo-ads')
    const promoAds = response.data
    reviewDataList(verifyPromoAd, 'Promo Ad', promoAds)
    return promoAds
}

export interface PromoAdInfo {
    name: string
    image: any
}

const createPromoAd = async (adInfo: PromoAdInfo) => {
    const formData = new FormData()
    formData.append('name', adInfo.name)
    formData.append('image', adInfo.image)
    const result = await axios.post('promo-ads', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
    return result
}

export interface PromoAdBuilderClient {
    id: number
    name: string
    shortName: string
    dealerCode?: string
    website?: string
    makes: string
}

const verifyPromoAdBuilderClient = (client: PromoAdBuilderClient) => {
    const { id, name, shortName, dealerCode, website, makes } = client
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isFilledString(name)
    checks.shortName = isString(shortName)
    checks.dealerCode = isOptional(isFilledString, dealerCode)
    checks.website = isOptional(isFilledString, website)
    checks.makes = isString(makes)

    return checks
}

const promoAdBuilderClients = async (): Promise<PromoAdBuilderClient[]> => {
    const response = await axios.get('promo-ad-builder/clients')
    const clientList = response.data
    reviewDataList(
        verifyPromoAdBuilderClient,
        'Promo Ad Builder Client',
        clientList,
    )
    return clientList
}

const updatePromoAdBuilderInfo = async (clientId, clientInfo) => {
    const result = await axios.post(
        `promo-ad-builder/clients/${clientId}`,
        clientInfo,
    )
    return result
}

export interface AdPreview {
    height: number
    width: number
    html: string
}

const verifyAdPreview = (adPreview: AdPreview) => {
    const { height, width, html } = adPreview
    let checks: Checklist = {}
    checks.height = isPositiveInteger(height)
    checks.width = isPositiveInteger(width)
    checks.html = isFilledString(html)

    return checks
}

export interface ClientAdPreviews {
    client: PromoAdBuilderClient
    adPreviews: AdPreview[]
}

const verifyClientAdPreviews = (clientPreviews: ClientAdPreviews) => {
    const { client, adPreviews } = clientPreviews
    let checks: Checklist = {}
    checks.client = reviewChecklist(
        verifyPromoAdBuilderClient,
        'Promo Ad Builder Client',
        client,
    )
    checks.adPreviews = reviewDataList(
        verifyAdPreview,
        'Ad Preview',
        adPreviews,
    )

    return checks
}

const clientAdPreviews = async (promoAdId: number, clientIdList: number[]) => {
    const result = await axios.post(
        `promo-ad-builder/${promoAdId}/preview`,
        clientIdList,
    )
    const adPreviews = result.data
    reviewDataList(verifyClientAdPreviews, 'Client Ad Previews', adPreviews)
    return adPreviews
}

export interface PromoAdsZipInfo {
    zipUrl: string
}

const verifyPromoAdsZipInfo = (info: PromoAdsZipInfo) => {
    const { zipUrl } = info
    let checks: Checklist = {}
    checks.zipUrl = isFilledString(zipUrl)

    return checks
}

const generatePromoAdsZip = async (
    promoAdId: number,
    clientIdList: number[],
) => {
    const result = await axios.post(
        `promo-ad-builder/${promoAdId}/generate`,
        clientIdList,
    )
    const promoAdsZipInfo = result.data
    reviewChecklist(
        verifyPromoAdsZipInfo,
        'Promo Ads Zip Info',
        promoAdsZipInfo,
    )
    return promoAdsZipInfo
}

const saveNewClient = async (clientInfo) => {
    const result = await axios.post('clients', clientInfo)
    return result
}

export interface Car {
    id: number
    make: string
    model: string
    trim: string | null
    condition: string
    certified: null | boolean
    year: number
    price: number | null
    msrp: number | null
    vin: string
    inStockDate: string | null
    location: string
    url: string
    images: string[]
}

const verifyCar = (car: Car) => {
    const {
        id,
        make,
        model,
        trim,
        condition,
        certified,
        year,
        price,
        msrp,
        vin,
        inStockDate,
        location,
        url,
        images,
    } = car
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.make = isFilledString(make)
    checks.model = isFilledString(model)
    checks.trim = isNullable(isFilledString, trim)
    checks.condition = isFilledString(condition)
    checks.certified = isNullable(isBoolean, certified)
    checks.year = isPositiveInteger(year)
    checks.price = isNullable(isNonNegativeNumber, price)
    checks.msrp = isNullable(isNonNegativeNumber, msrp)
    checks.vin = isFilledString(vin)
    checks.inStockDate = isNullable(isFilledString, inStockDate)
    checks.location = isFilledString(location)
    checks.url = isFilledString(url)
    checks.images = isArrayOf(isFilledString, images)

    return checks
}

export interface ClientWithCars {
    id: number
    name: string
    fourbotId: number
    cars: Car[]
}

const verifyClientWithCars = (clientWithCars: ClientWithCars) => {
    const { id, name, fourbotId, cars } = clientWithCars
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isFilledString(name)
    checks.fourbotId = isPositiveInteger(fourbotId)
    checks.cars = reviewDataList(verifyCar, 'Car', cars, false)

    return checks
}

const clientWithCars = async (clientId): Promise<ClientWithCars> => {
    const response = await axios.get(`clients/${clientId}/cars`)
    const clientWithCars = response.data
    reviewChecklist(verifyClientWithCars, 'ClientWithCars', clientWithCars)
    return clientWithCars
}

export interface FourbotValues {
    price: null | number
    discount: null | number
    lease: null | number
    stock: null | number
}

export interface CustomValues {
    price?: number
    discount?: number
    lease?: number
    stock?: number
}

export interface FeedValuesSet {
    fourbotValues: null | FourbotValues
    customValues: null | CustomValues
}
export interface FeedValues {
    [key: string]: FeedValuesSet
}

const clientFeedValues = async (clientId): Promise<FeedValues> => {
    const response = await axios.get(`clients/${clientId}/feed-values`)
    const feedValues = response.data
    //reviewChecklist(verifyFeedValues, 'Feed Value', feedValues)
    return feedValues
}

const updateClientFeedValue = async (clientId, key, attribute, value) => {
    const result = await axios.post(`clients/${clientId}/feed-values`, {
        key,
        attribute,
        value,
    })
    return result
}

export interface FourbotClient {
    id: number
    name: string
}

const verifyFourbotClient = (fourbotClient: any) => {
    const { id, name } = fourbotClient
    let checks: Checklist = {}
    checks.id = isWholeNumber(id)
    checks.name = isFilledString(name)

    return checks
}

const fourbotClients = async (): Promise<FourbotClient[]> => {
    const response = await axios.get('fourbot-clients')
    const fourbotClients = response.data
    reviewDataList(verifyFourbotClient, 'Fourbot Client', fourbotClients)
    return fourbotClients
}

export interface SourceValidationResponse {
    valid: boolean
    message: string
}

const verifySourceValidationResponse = (response: any) => {
    const { valid, message } = response
    let checks: Checklist = {}
    checks.valid = isBoolean(valid)
    checks.message = isFilledString(message)

    return checks
}

const validateSourceId = async ({
    source,
    accountId,
}): Promise<SourceValidationResponse> => {
    try {
        const response = await axios.post('valid-source', { source, accountId })
        const validationResponse = response.data
        reviewChecklist(
            verifySourceValidationResponse,
            'Source Validation Response',
            validationResponse,
        )
        return validationResponse
    } catch (error) {
        return { valid: false, message: 'API error' }
    }
}

export interface ProductLineItem {
    id: number
    name: string
    campaignType: null | string
    label: string
    source: Source
    deletedAt?: string
}

export interface PromoLineItem {
    id: number
    name: string
    lineItems: { label: string; source: Source }[]
    deletedAt?: string
}

const verifyProductLineItem = (lineItem: any) => {
    const { id, name, campaignType, label, source, deletedAt } = lineItem
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isFilledString(name)
    checks.deletedAt = isOptional(isIsoTimestamp, deletedAt)
    checks.campaignType = isNullable(isFilledString, campaignType)
    checks.label = isFilledString(label)
    checks.source = isAdSource(source)

    return checks
}

const verifySourcePromoLineItem = (sourceLineItem: any) => {
    const { label, source } = sourceLineItem
    let checks: Checklist = {}
    checks.label = isFilledString(label)
    checks.source = isAdSource(source)

    return checks
}

const verifyPromoLineItem = (lineItem: any) => {
    const { id, name, lineItems, deletedAt } = lineItem
    let checks: Checklist = {}
    checks.id = isPositiveInteger(id)
    checks.name = isFilledString(name)
    checks.lineItems = reviewDataList(
        verifySourcePromoLineItem,
        'Source Promo Line Item',
        lineItems,
    )
    checks.deletedAt = isOptional(isIsoTimestamp, deletedAt)

    return checks
}

const productLineItems = async (): Promise<ProductLineItem[]> => {
    const response = await axios.get('product-line-items')
    const lineItems = response.data
    reviewDataList(verifyProductLineItem, 'Product Line Item', lineItems)
    return lineItems
}

const promoLineItems = async (): Promise<PromoLineItem[]> => {
    const response = await axios.get('promo-line-items')
    const lineItems = response.data
    reviewDataList(verifyPromoLineItem, 'Promo Line Item', lineItems)
    return lineItems
}

const updateProductLineItems = async (updatedLineItems) => {
    const result = await axios.post('product-line-items', updatedLineItems)
    return result
}

const updatePromoLineItems = async (updatedLineItems) => {
    const result = await axios.post('promo-line-items', updatedLineItems)
    return result
}

const downloadMappingsFile = async (type, active) => {
    let requestString = ''
    // Promos or Products mappings
    if (type === 'promos') {
        requestString += 'promo-line-items-csv'
    } else {
        requestString += 'product-line-items-csv'
    }
    // Active mappings only?
    if (!!active) {
        requestString += '?active=true'
    }

    const response = await axios.get(requestString)

    if (response && response.data) {
        const csvFile = response.data
        return csvFile
    } else {
        return false
    }
}

const uploadMappingsFile = async (selectedFile) => {
    const formData = new FormData()
    formData.append('csv', selectedFile)

    try {
        const response = await axios.post('promo-line-items-csv', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        const data = response.data
        return data
    } catch (error) {
        console.error('Error uploading file', error)
        return false
    }
}

const uploadBudgetAssignments = async (selectedFile) => {
    try {
        const formData = new FormData()
        formData.append('csv', selectedFile)
        const response = await axios.post('bulk-assign', formData)
        return response
    } catch (error) {
        console.error('Error caught:', error)

        if (error.response) {
            console.error('Error Response:', error.response)
            console.error('Error Data:', error.response.data)
            console.error('Error Status:', error.response.status)
        } else {
            console.error('Error without response:', error)
        }
        return false
    }
}
const uploadBudgets = async (selectedFile) => {
    try {
        const formData = new FormData()
        formData.append('csv', selectedFile)
        const response = await axios.post('upload-budgets', formData)
        return response
    } catch (error) {
        console.error('Error caught:', error)

        if (error.response) {
            console.error('Error Response:', error.response)
            console.error('Error Data:', error.response.data)
            console.error('Error Status:', error.response.status)
        } else {
            console.error('Error without response:', error)
        }
        return false
    }
}

interface ClientNote {
    clientId: number
    note: string
}

const verifyClientNote = (clientNote: ClientNote) => {
    const { clientId, note } = clientNote || {}
    let checks: Checklist = {}
    checks.clientId = isPositiveInteger(clientId)
    checks.note = isString(note)

    return checks
}

const clientNote = async (clientId): Promise<ClientNote> => {
    const response = await axios.get(`clients/${clientId}/note`)
    const clientNote = response.data
    clientNote && reviewChecklist(verifyClientNote, 'Client Note', clientNote)
    return clientNote
}

const updateClientNote = async (clientId, note) => {
    const result = await axios.post(`clients/${clientId}/note`, note)
    return result
}

interface SalesforceProduct {
    assetId: string
    productName: string
}

interface CategorizedCampaignSpends {
    clientName: string
    clientDealerCode?: string
    adSpendBudget: number
    monthToDateSpend: number
    salesforceProduct: SalesforceProduct | null
    campaigns: {
        name: string
    }[]
}
interface BudgetSpendWatchdogActions {
    actionDate: string
    actionTaken?: string
    campaigns: {
        name: string
    }[]
}

interface BudgetSpendWatchdog {
    id: number
    percentBudgetSpent: number | null
    budgetSpendDifference: number | null
    ignore: boolean
    overspend: number
    updatedAt: string
    categorizedCampaignSpends: CategorizedCampaignSpends
    actions: BudgetSpendWatchdogActions
}

const budgetSpendWatchdogs = async (
    yearMonth = '',
    clientSourceId = '',
): Promise<BudgetSpendWatchdog[]> => {
    let url = yearMonth
        ? `budget-spend-watchdogs?yearMonth=${yearMonth}`
        : 'budget-spend-watchdogs?'
    url = clientSourceId ? url + `&clientSourceId=${clientSourceId}` : url
    const response = await axios.get(url)
    const budgetSpendWatchdogs = response.data
    // TODO: Validate data here
    return budgetSpendWatchdogs
}

const updateBudgetSpendWatchdog = async (id, values) => {
    const body = {} as { ignore?: boolean; overspend?: number }
    if (values.ignore !== undefined) {
        body.ignore = values.ignore
    }
    if (values.overspend !== undefined) {
        body.overspend = values.overspend
    }
    const result = await axios.post(`budget-spend-watchdogs/${id}`, body)
    return result
}

export interface UsedToolUrlSet {
    makeUrl: string
    makeModelUrl: string
    yearMakeModelUrl: string
}

const verifyUsedToolUrlSet = (urlSet: any) => {
    const { makeUrl, makeModelUrl, yearMakeModelUrl } = urlSet
    let checks: Checklist = {}
    checks.makeUrl = isString(makeUrl)
    checks.makeModelUrl = isString(makeModelUrl)
    checks.yearMakeModelUrl = isString(yearMakeModelUrl)

    return checks
}

interface ClientUsedToolUrls {
    id: number
    name: string
    urls: UsedToolUrlSet
}

const verifyClientUsedToolUrls = (clientUrls: any) => {
    const { id, name, urls } = clientUrls
    let checks: Checklist = {}
    checks.id = isNonNegativeNumber(id)
    checks.name = isString(name)
    checks.urls = reviewChecklist(
        verifyUsedToolUrlSet,
        'Used Tool Url Set',
        urls,
    )

    return checks
}

const clientUsedToolUrls = async (
    clientId: string,
): Promise<ClientUsedToolUrls> => {
    const response = await axios.get(`used-tool/${clientId}/urls`)
    const clientUrls = response.data
    reviewChecklist(
        verifyClientUsedToolUrls,
        'Used Tool Client Urls',
        clientUrls,
    )
    return clientUrls
}

const updateClientUsedToolUrls = async (
    clientId: string,
    urls: UsedToolUrlSet,
) => {
    const result = await axios.post(`used-tool/${clientId}/urls`, urls)
    return result
}

export interface UsedToolStatus {
    /** Unique ID for this used tool run */
    id: number
    /** Keyword hits for this client */
    hits: number
    /** Total number of cars for this client */
    inventory: number
    /** ISO format */
    time: string
    client: {
        id: number
        name: string
        dealerCode: string | null
    }
    misses: {
        /** The keyword that was missed */
        value: string
        /** The number of vehicles with the keyword */
        stock: number
    }[]
}

export interface AutomaticPricingClients {
    /** Unique ID for this client */
    id: number
    /** Keyword hits for this client */
    hits: number
    /** Total number of cars for this client */
    inventory: number
    /** ISO format */
    time: string
    client: {
        id: number
        name: string
        dealerCode: string | null
    }
    misses: {
        /** The keyword that was missed */
        value: string
        /** The number of vehicles with the keyword */
        stock: number
    }[]
}

export interface AutomaticPricingModels {
    /** Unique ID for this client */
    makeModel: string
    /** Total number of cars */
    stock: number
    clients: {
        id: number
        name: string
        dealerCode: string
    }
}

const verifySimpleClient = (simpleClient: any) => {
    const { id, name, dealerCode } = simpleClient
    let checks: Checklist = {}
    checks.id = isWholeNumber(id)
    checks.name = isString(name)
    checks.dealerCode = isNullable(isString, dealerCode)

    return checks
}

/* const verifySimpleModel = (simpleModel: any) => {
    console.log(simpleModel)
    const { id, name, dealerCode } = simpleModel
    let checks: Checklist = {}
    checks.id = isWholeNumberOrString(id)
    checks.name = isString(name)
    checks.dealerCode = isNullable(isString, dealerCode)

    return checks
} */

const verifyMissesReport = (missesReport: any) => {
    const { value, stock } = missesReport
    let checks: Checklist = {}
    checks.value = isString(value)
    checks.stock = isWholeNumber(stock)

    return checks
}

const verifyUsedToolStatus = (status: any) => {
    const { id, hits, inventory, time, client, misses } = status
    let checks: Checklist = {}
    checks.id = isWholeNumber(id)
    checks.hits = isWholeNumber(hits)
    checks.inventory = isWholeNumber(inventory)
    checks.time = isString(time)
    checks.client = reviewChecklist(
        verifySimpleClient,
        'Used Tool Status - Simple Client',
        client,
    )
    checks.misses = reviewDataList(verifyMissesReport, 'Misses Report', misses)

    return checks
}

const verifyAutomaticPricingClients = (status: any) => {
    const { id, hits, inventory, time, client, misses } = status
    let checks: Checklist = {}
    checks.id = isWholeNumber(id)
    checks.hits = isWholeNumber(hits)
    checks.inventory = isWholeNumber(inventory)
    checks.time = isString(time)
    checks.client = reviewChecklist(
        verifySimpleClient,
        'Automatic Pricing Clients - Simple Client',
        client,
    )
    checks.misses = reviewDataList(verifyMissesReport, 'Misses Report', misses)

    return checks
}

const verifyAutomaticPricingModels = (status: any) => {
    const item = status as {
        makeModel: string
        stock: number
        clients: { id: number; name: string; dealerCode: string }[]
    }
    let checks: Checklist = {}
    const { makeModel, stock } = item
    checks.makeModel = isString(makeModel)
    checks.stock = isWholeNumber(stock)
    checks.clients = true
    // TODO: Validate clients data here
    /* checks.clients = reviewChecklist(
        verifySimpleModel,
        'Automatic Pricing Models - Simple Client',
        clients,
    )*/
    return checks
}

const usedToolStatus = async (): Promise<UsedToolStatus[]> => {
    const response = await axios.get(`used-tool/coverage`)
    const status = response.data
    reviewDataList(verifyUsedToolStatus, 'Used Tool Client Status', status)
    return status
}

const automaticPricingClients = async (): Promise<
    AutomaticPricingClients[]
> => {
    const response = await axios.get(`automatic-pricing/coverage`)
    const status = response.data
    reviewDataList(
        verifyAutomaticPricingClients,
        'Automatic Pricing Clients',
        status,
    )
    return status
}

const automaticPricingModels = async (): Promise<AutomaticPricingModels[]> => {
    const response = await axios.get(`automatic-pricing/make-model-coverage`)
    let data = response.data
    if (data) {
        data = Object.values(data)
    }
    reviewDataList(
        verifyAutomaticPricingModels,
        'Automatic Pricing Models',
        data,
    )
    return data
}

interface ClientExcludedTerms {
    id: number
    name: string
    terms: string[]
}

const verifyClientExcludedTerms = (clientTerms: any) => {
    const { id, name, terms } = clientTerms
    let checks: Checklist = {}
    checks.id = isNonNegativeNumber(id)
    checks.name = isString(name)
    checks.terms = isArray(isFilledString, terms)

    return checks
}

const clientExcludedTerms = async (
    clientId: string,
): Promise<ClientExcludedTerms> => {
    const response = await axios.get(`used-tool/${clientId}/excluded-terms`)
    const excludedTerms = response.data
    reviewChecklist(
        verifyClientExcludedTerms,
        'Client Excluded Terms',
        excludedTerms,
    )
    return excludedTerms
}

const updateClientExcludedTerms = async (clientId: string, terms: string[]) => {
    const body = { terms }
    const result = await axios.post(
        `used-tool/${clientId}/excluded-terms`,
        body,
    )
    return result
}

// Client CoOp Report

interface ClientCoopReport {
    id: number | null
    name: string
}

const verifyCoopReportClient = (client: any) => {
    const { id, name } = client
    let checks: Checklist = {}
    checks.id = isNullable(isPositiveInteger, id)
    checks.name = isString(name)

    return checks
}

export interface CoopReport {
    client: ClientCoopReport
    startDate: IsoDate
    endDate: IsoDate
    filterType: string | null
    filterTerms: string | null
    includeGoogle: boolean | null
    includeMicrosoft: boolean | null
    includeFacebook: boolean | null
    includeYouTube: boolean | null
    includeSeo: boolean | null
    includeNotary: boolean | null
    saveAsDefault?: boolean
}

const verifyClientCoopReport = (coopReport: any) => {
    const {
        client,
        startDate,
        endDate,
        filterType,
        filterTerms,
        includeGoogle,
        includeMicrosoft,
        includeFacebook,
        includeYouTube,
        includeSeo,
        includeNotary,
        saveAsDefault,
    } = coopReport
    let checks: Checklist = {}
    checks.client = reviewChecklist(
        verifyCoopReportClient,
        'CoopReport Client',
        client,
    )
    checks.startDate = isIsoTimestamp(startDate)
    checks.endDate = isIsoTimestamp(endDate)
    checks.filterType = isString(filterType)
    checks.filterTerms = isString(filterTerms)
    checks.includeGoogle = isNullable(isBoolean, includeGoogle)
    checks.includeMicrosoft = isNullable(isBoolean, includeMicrosoft)
    checks.includeFacebook = isNullable(isBoolean, includeFacebook)
    checks.includeYouTube = isNullable(isBoolean, includeYouTube)
    checks.includeSeo = isNullable(isBoolean, includeSeo)
    checks.includeNotary = isNullable(isBoolean, includeNotary)
    checks.saveAsDefault = isOptional(isBoolean, saveAsDefault)

    return checks
}

const getClientCoopReport = async (clientId: string): Promise<CoopReport> => {
    const response = await axios.get(`coop-report/${clientId}`, {
        validateStatus: function (status) {
            return status === 200
        },
    })
    const coopReport = response.data
    reviewChecklist(verifyClientCoopReport, 'Client Co-Op Report', coopReport)

    return coopReport
}

const saveClientCoopReport = async (clientId, clientInfo) => {
    const result = await axios.post(`coop-report/${clientId}`, clientInfo)
    return result
}

//

export interface InventoryTranslation {
    clientId: number | null
    feed: string
    field: string
    fourbotValue: string
    matchType: string
    replacement: string
}

export interface FeedAndClientInventoryTranslations {
    clientId: number | null
    feed: string
    translations: [
        {
            field: string
            fourbotValue: string
            matchType: string
            replacement: string
        },
    ]
}

const inventoryTranslations = async (): Promise<InventoryTranslation[]> => {
    const response = await axios.get(`inventory-translations`)
    const translations = response.data
    return translations
}

const updateInventoryTranslations = async (
    translations: FeedAndClientInventoryTranslations,
) => {
    const result = await axios.post(`inventory-translations`, translations)
    return result
}

export interface FeedHash {
    id?: number
    hash?: string
    params?: string
    hashedUrl?: string
    debugUrl?: string
    url: string
    createdAt?: string
}

const feedHashes = async (): Promise<FeedHash[]> => {
    const response = await axios.get(`feed-hashes`)
    const feedHashes = response.data
    return feedHashes
}

const saveFeedHash = async (feedHash: FeedHash) => {
    const result = await axios.post(`feed-hashes`, feedHash)
    return result
}

export interface AutoClient {
    id: number
    name: string
    fourbotId?: number
    dealerCode?: string
}

const autoClients = async (): Promise<AutoClient[]> => {
    const response = await axios.get(`auto-clients`)
    const autoClients = response.data
    return autoClients
}

export interface Batch {
    id: string
    name: string
    totalJobs: number
    pendingJobs: number
    processedJobs: number
    progress: number
    failedJobs: number
    options: string[]
    createdAt: string
    cancelledAt: string | null
    finishedAt: string | null
}

const batch = async (batchId: string): Promise<Batch> => {
    const response = await axios.get(`batch/${batchId}`)
    const batch = response.data
    return batch
}

export const SafeguardAlertTypes = {
    NO_CLIENT: 'No Client',
    NO_LINE_ITEM: 'No Line Item',
    BUDGET_MISMATCH: 'Budget Mismatch',
    NO_SALESFORCE_BUDGET: 'No Salesforce Budget',
    UNASSIGNED: 'Unassigned',
    NO_RECENT_LOGIN: 'No Recent Login',
    SPENDING_AFTER_END_DATE: 'Spending after End Date',
} as const

export type SafeguardAlertType =
    typeof SafeguardAlertTypes[keyof typeof SafeguardAlertTypes]

export interface AdAccount {
    id: string
    name: null | string
    source: Source
}

interface Campaign {
    id: string
    name: string
}

interface ClientSummary {
    id: string
    name: string
    type?: string
}

interface BudgeterSummary {
    id?: number
    name: string
}

export interface SafeguardAlert {
    id: number
    type: SafeguardAlertType
    spend: number
    adAccount: AdAccount
    campaigns: Campaign[]
    client?: ClientSummary
    budgeter?: BudgeterSummary
    ignoredAt?: string
    permanentlyIgnore?: boolean
}

const safeguardAlerts = async (): Promise<SafeguardAlert[]> => {
    const response = await axios.get(`safeguard/alerts`)
    const alerts = response.data

    const formattedAlerts = alerts.map((alert) => {
        if (!alert.client) {
            alert.client = { type: 'Unknown' }
        }
        return alert
    })

    return formattedAlerts
}

const setSafeguardAlertIgnore = async (alertId: number, ignore: boolean) => {
    const result = await axios.post(`safeguard/alerts/${alertId}`, { ignore })
    return result
}

const PearApi = {
    autoClients,
    automaticPricingClients,
    automaticPricingModels,
    batch,
    budget,
    budgetList,
    budgetSpendWatchdogs,
    client,
    clientAdPreviews,
    clientCampaigns,
    clientExcludedTerms,
    clientFeedValues,
    clientNote,
    clientUsedToolUrls,
    clientWithCars,
    clients,
    createPromoAd,
    currentUser,
    downloadMappingsFile,
    feedHashes,
    fourbotClients,
    generatePromoAdsZip,
    getClientCoopReport,
    importSpends,
    inventoryTranslations,
    loginWithGoogleAuth,
    logout,
    productLineItems,
    promoAdBuilderClients,
    promoAds,
    promoLineItems,
    refreshToken,
    safeguardAlerts,
    saveBudget,
    saveClient,
    saveClientCoopReport,
    saveFeedHash,
    saveNewClient,
    setSafeguardAlertIgnore,
    spendBreakdown,
    updateBudgetSpendWatchdog,
    updateClientExcludedTerms,
    updateClientFeedValue,
    updateClientNote,
    updateClientUsedToolUrls,
    updateInventoryTranslations,
    updateProductLineItems,
    updatePromoAdBuilderInfo,
    updatePromoLineItems,
    uploadBudgetAssignments,
    uploadBudgets,
    uploadMappingsFile,
    usedToolStatus,
    users,
    validateSourceId,
}

export default PearApi
