import { memo, useCallback } from 'react'
import { useMutation } from 'react-query'
import { Form } from 'react-final-form'
import { useTable } from 'react-table'
import { SectionCloud } from '../components/Layout'
import { CirclePlusButton } from '../components/elements/Buttons'
import { TextField } from '../components/forms/Fields'
import { classNames } from '../utility/Components'
import StatusDisplay from '../components/StatusDisplay'
import { invalidateFeedHashes, useFeedHashes } from '../hooks/apiHooks'
import PearApi from '../apis/pearApi'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'

const spacings = {
    hash: 'w-40',
    hashedUrl: 'flex-1 min-w-0',
    debugUrl: 'w-36',
    createdAt: 'w-36',
}

const columns = [
    {
        Header: 'Hash',
        accessor: 'hash',
    },
    {
        Header: 'Debug URL',
        accessor: 'debugUrl',
    },
    {
        Header: 'Hashed URL',
        accessor: 'hashedUrl',
    },
    {
        Header: 'Date Created',
        accessor: 'createdAt',
    },
]

const FeedHashes = () => {
    const { data: feedHashes = [], status } = useFeedHashes()

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data: feedHashes,
        })

    const { mutate: saveFeedHashes, isLoading: isSavingFeedHashes } =
        useMutation(PearApi.saveFeedHash, {
            onSettled: () => {
                invalidateFeedHashes()
            },
        })

    const RenderRow = useCallback(
        ({ index }) => {
            const row = rows[index]
            prepareRow(row)
            return (
                <Row
                    row={row}
                    {...row.getRowProps()}
                    saveFeedHashes={saveFeedHashes}
                    isSavingFeedHashes={isSavingFeedHashes}
                />
            )
        },
        [prepareRow, rows, saveFeedHashes, isSavingFeedHashes],
    )

    return (
        <SectionCloud className="flex h-full flex-col py-6 px-8">
            <h1 className="flex-none text-3xl font-extrabold tracking-tight text-gray-600">
                Feed Hashes
            </h1>
            <Form
                onSubmit={({ url }) =>
                    saveFeedHashes({
                        url: url,
                    })
                }
                render={({ handleSubmit, invalid, pristine, submitting }) => (
                    <form
                        noValidate={true}
                        onSubmit={handleSubmit}
                        className="mt-4 flex flex-none items-end space-x-6"
                    >
                        <TextField
                            className={'mb-5 w-4/5'}
                            label="URL"
                            name="url"
                        />
                        <div className="pb-5">
                            <CirclePlusButton
                                alt="Submit Feed to Hash"
                                disabled={
                                    invalid ||
                                    pristine ||
                                    submitting ||
                                    isSavingFeedHashes
                                }
                                type="submit"
                            />
                        </div>
                    </form>
                )}
            />
            <div className="relative min-h-0 flex-auto">
                <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                        <div
                            className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-lg"
                            {...getTableProps()}
                        >
                            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                                <div
                                    className="flex-none bg-gray-50 pl-2"
                                    style={{ width: 'calc(100% - 0.9em)' }}
                                >
                                    {headerGroups.map((headerGroup) => (
                                        <div
                                            className="flex gap-6"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => {
                                                    const { id } = column ?? {}
                                                    const header =
                                                        column.render('Header')
                                                    return (
                                                        <div
                                                            className={classNames(
                                                                'group py-2 text-xs font-medium uppercase tracking-wider text-gray-700 focus:text-cyan-900 focus:outline-none',
                                                                spacings[id],
                                                            )}
                                                            {...column.getHeaderProps()}
                                                            title={undefined}
                                                        >
                                                            {header}
                                                        </div>
                                                    )
                                                },
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="flex-1 overflow-y-scroll bg-white py-1"
                                    {...getTableBodyProps()}
                                >
                                    {rows.length > 0 ? (
                                        rows.map(RenderRow)
                                    ) : status !== 'success' ? (
                                        <div className="h-full">
                                            <StatusDisplay status={status} />
                                        </div>
                                    ) : (
                                        <div className="block w-full">
                                            <div className="block w-full">
                                                <div className="p-16 text-center text-lg">
                                                    There's nothing here.
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionCloud>
    )
}

export default FeedHashes

const Row = memo(
    ({
        row,
        clientInfoIndex,
    }: {
        row: any
        clientInfoIndex: { [clientId: string]: string }
    }) => {
        return (
            <div className="flex items-center gap-6 rounded-md pt-4 pl-2 text-sm">
                {row.cells.map((cell) => {
                    const { id } = cell?.column ?? {}

                    return id === 'debugUrl' ? (
                        <div
                            className={classNames(
                                '-my-3 flex items-center justify-between truncate py-2 pr-5 text-gray-700',
                                spacings[id],
                            )}
                        >
                            <a
                                href={cell.value}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Debug URL
                                <ArrowTopRightOnSquareIcon
                                    className="ml-1 inline h-4 text-xs"
                                    aria-hidden="true"
                                />
                            </a>
                        </div>
                    ) : (
                        <div
                            className={classNames(
                                '-my-3 flex items-center justify-between truncate py-2 pr-5 text-gray-700',
                                spacings[id],
                            )}
                            {...cell.getCellProps()}
                        >
                            {cell.render('Cell', { clientInfoIndex })}{' '}
                        </div>
                    )
                })}
            </div>
        )
    },
)
